














import Vue from "vue";
import { mdiChevronDown } from "@mdi/js";

export default Vue.extend({
  name: "FAQEntry",
  props: ["question", "answer"],
  data: () => ({
    icon: mdiChevronDown,
  }),
});
