


























































































































































































import Vue from "vue";
import FAQEntry from "@/components/faq/FAQEntry.vue";

export default Vue.extend({
  name: "FAQ",
  components: { FAQEntry },
  props: {
    preSelectedEntry: {
      type: Number,
      default: -1,
    },
  },
  data: () => ({
    selectedEntry: undefined as number | undefined,
  }),
  mounted() {
    let elementArray = this.$el.getElementsByClassName("faq-entry");

    if (
      elementArray.length > 0 &&
      this.preSelectedEntry >= 0 &&
      this.preSelectedEntry < elementArray.length
    ) {
      this.selectedEntry = this.preSelectedEntry;
      elementArray[this.preSelectedEntry].scrollIntoView({
        block: "center",
      });
    }
  },
});
